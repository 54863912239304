<template>
  <v-dialog :value="value" width="600" persistent scrollable :fullscreen="$vuetify.breakpoint.mobile">
    <v-card outlined>
      <v-card-title class="mb-4">
        <h4 class="primary--text">{{ $t('filters.loadsDialog.title') }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">

          <h3>{{ $t('filters.loadsDialog.details') }}</h3>
          <v-row class="mt-1 mb-4" dense>
            <v-col v-if="freightMode" cols="12">
              <label for="freightCategory">{{ $t('filters.loadsDialog.freightCategory') }}</label>
              <v-select
                v-model="filters.vehicleFreightTypes.value"
                id="freightCategory"
                :items="freightTypes"
                item-text="description"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                clearable
                hide-details="auto"
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <label for="minLoadMeters">{{ $t('filters.loadsDialog.minLoadMeters') }}</label>
              <v-text-field
                v-model.number="filters.minLoadMeters.value"
                id="minLoadMeters"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers, validations.loadMeters]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <label for="maxLoadMeters">{{ $t('filters.loadsDialog.maxLoadMeters') }}</label>
              <v-text-field
                v-model.number="filters.maxLoadMeters.value"
                id="maxLoadMeters"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers, validations.loadMeters]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <label for="width">{{ $t('filters.loadsDialog.width') }}</label>
              <v-text-field
                v-model.number="filters.minWidth.value"
                id="width"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <label for="height">{{ $t('filters.loadsDialog.height') }}</label>
              <v-text-field
                v-model.number="filters.minHeight.value"
                id="height"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers]"
              />
            </v-col>
            <v-col cols="12" md="4">
              <label for="volume">{{ $t('filters.loadsDialog.volume') }}</label>
              <v-text-field
                v-model.number="filters.minVolume.value"
                id="volume"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers]"
              />
            </v-col>
            <v-col cols="12">
              <label for="vehicleLoadFormType">{{ $t('filters.loadsDialog.vehicleLoadFormType') }}</label>
              <v-select
                v-model="filters.loadForms.value"
                id="vehicleLoadFormType"
                :items="vehicleLoadFormTypes"
                item-text="description"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                multiple
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <label for="options">{{ $t('filters.loadsDialog.options') }}</label>
              <v-select
                v-model="filters.options.value"
                id="options"
                :items="vehicleOptions"
                item-text="translatedValues[0].name"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                multiple
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col v-if="freightMode" cols="12">
              <label for="freightType">{{ $t('filters.loadsDialog.freightType') }}</label>
              <v-select
                v-model="filters.freightType.value"
                id="freightType"
                :items="freightTypeList"
                item-text="translatedValues[0].name"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                multiple
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col v-if="freightMode" cols="12">
              <label for="companyRating">{{ $t('filters.loadsDialog.review') }}</label>
              <v-select
                v-model="filters.companyRating.value"
                id="companyRating"
                :items="reviews"
                item-text="label"
                item-value="value"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col v-if="freightMode" cols="12">
              <label for="companyPayerStatus">{{ $t('filters.loadsDialog.companyPayerStatus') }}</label>
              <v-select
                v-model="filters.companyPayerStatus.value"
                id="companyPayerStatus"
                :items="companyPayerStatuses"
                item-text="description"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                multiple
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <label for="search">{{ $t('filters.loadsDialog.search') }}</label>
              <v-text-field
                v-model="filters.search.value"
                id="search"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
              />
            </v-col>
            <v-col v-if="freightMode" cols="12" md="4">
              <label for="minPrice">{{ $t('filters.loadsDialog.minPrice') }}</label>
              <v-text-field
                v-model.number="filters.minPrice.value"
                id="minPrice"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers]"
              />
            </v-col>
            <v-col v-if="freightMode" cols="12" md="4">
              <label for="maxPrice">{{ $t('filters.loadsDialog.maxPrice') }}</label>
              <v-text-field
                v-model.number="filters.maxPrice.value"
                id="maxPrice"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers]"
              />
            </v-col>
            <v-col v-if="freightMode" cols="12" md="4">
              <label for="currency">{{ $t('filters.loadsDialog.currency') }}</label>
              <v-select
                v-model="filters.currency.value"
                id="currency"
                :items="allCurrencies"
                item-text="description"
                item-value="id"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="12" v-if="freightMode">
              <label>{{ $t('freight.paymentDeadline') }}</label>
              <v-select
                v-model="filters.paymentType.value"
                ref="paymentType"
                :items="paymentTypes"
                item-text="description"
                item-value="id"
                validate-on-blur
                single-line
                dense
                outlined
                clearable
                hide-details="auto"
                @change="paymentTypeChange"
              ></v-select>
            </v-col>
            <v-col v-if="freightMode && filters.paymentType.value === 1" cols="12">
              <label for="daysForPayment">{{ $t('filters.loadsDialog.numberOfDays') }}</label>
              <v-text-field
                v-model.number="filters.daysForPayment.value"
                id="daysForPayment"
                type="number"
                outlined
                required
                validate-on-blur
                dense
                single-line
                hide-details="auto"
                :rules="[validations.onlyNaturalNumbers]"
              />
            </v-col>
            <v-col cols="12">
              <div class="d-flex">
                <v-checkbox v-model="filters.blockedCooperation.value" :false-value="true" :true-value="false" class="pa-0 ma-0" hide-details>
                  <template #label>
                    <span>{{ $t('filters.loadsDialog.blockedCooperation') }}</span>
                  </template>
                </v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pa-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined min-width="120" @click="$emit('input', false)">
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn color="primary" outlined min-width="120" @click="confirm">
            {{ $t('filters.setFilter') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Service from '@/services'
import moment from 'moment'

export default {
  name: 'MoreFiltersDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      valid: true,
      vehicleOptions: [],
      freightTypeList: [],
      reviews: [
        {
          label: this.$t('reviews.better'),
          value: 4
        },
        {
          label: this.$t('reviews.good'),
          value: 3
        }
      ],
      validations: {
        onlyNaturalNumbers: (v) => v ? v > 0 ? true : this.$t('validation.onlyNaturalNumbers') : true,
        load: () => (this.filters.minLoad.value && this.filters.maxLoad.value) ? this.filters.minLoad.value <= this.filters.maxLoad.value : true,
        start: () => (this.filters.minStart.value && this.filters.minStart.value.length > 0 && this.filters.maxStart.value && this.filters.maxStart.value.length > 0) ? moment(this.filters.minStart.value).unix() > moment(this.filters.maxStart.value).unix() ? this.$t('validation.dateRange') : true : true,
        end: () => (this.filters.minEnd.value && this.filters.minEnd.value.length > 0 && this.filters.maxEnd.value && this.filters.maxEnd.value.length > 0) ? moment(this.filters.minEnd.value).unix() > moment(this.filters.maxEnd.value).unix() ? this.$t('validation.dateRange') : true : true,
        loadMeters: () => (this.filters.minLoadMeters.value && this.filters.maxLoadMeters.value) ? this.filters.minLoadMeters.value > this.filters.maxLoadMeters.value ? this.$t('validation.invalidRange') : true : true
      }
    }
  },
  computed: {
    filters: {
      get () {
        return this.data
      },
      set () {}
    },
    vehicleLoadFormTypes () {
      return this.$store.getters.enums.vehicleLoadFormTypes
    },
    freightMode () {
      return this.$route.name === 'Loads'
    },
    allCurrencies () {
      return this.$store.getters.allCurrencies
    },
    freightTypes () {
      return this.$store.getters.enums.freightTypes
    },
    companyPayerStatuses () {
      return this.$store.getters.enums.companyPayerStatus
    },
    paymentTypes () {
      return this.$store.getters.enums.dateOfPayments
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const { data: vehicleOptions } = await Service.get('Dictionary/GetVehicleOptions')
        this.vehicleOptions = vehicleOptions
        const { data: freightTypeList } = await Service.get('Dictionary/GetFreightTypes')
        this.freightTypeList = freightTypeList
      } catch (e) {
      }
    },
    confirm () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('change-filters', this.filters)
      this.$emit('input', false)
    },
    paymentTypeChange () {
      this.filters.daysForPayment.value = null
    }
  }
}
</script>

<style scoped>

</style>
